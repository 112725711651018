<template>
  <div class="home-container">
    <div class="home-container-left">
      <div class="block-container">
        <small-block
          :item="item"
          :bgColor="false"
          v-for="item in items"
          :key="item.id"
          class="small-block"
          @click.native="toDetail(item.title)"
        ></small-block>
      </div>
      <table-tab :tabs="tabs" @changeTab="changeTab"></table-tab>
      <div class="content">
        <device-table
          :tableData="tableData"
          :listSum="listSum"
          v-loading="deviceTableLoading"
          @refreshTable="refreshTable"
        ></device-table>
      </div>
    </div>
    <div class="home-container-right">
      <fresh-air-states class="state"></fresh-air-states>
      <online class="online"></online>
      <alarm1
        class="alarm1"
        :alarmList="$store.state.alarmList.slice(0, 3)"
      ></alarm1>
    </div>
  </div>
</template>

<script>
import {
  FreshAirStates,
  SmallBlock,
  DeviceTable,
  Online,
  Alarm1,
} from "../../components/widget";
import { TableTab } from "../../components/layout";

export default {
  name: "Home",
  components: {
    SmallBlock,
    DeviceTable,
    FreshAirStates,
    Online,
    Alarm1,
    TableTab,
  },
  data() {
    return {
      deviceTableLoading: false,
      items: [
        {
          id: 1,
          title: "新风系统",
          num: 0,
          extra: "",
        },
        {
          id: 2,
          title: "初效滤网",
          num: 0,
          extra: "",
        },
        {
          id: 3,
          title: "中效滤网",
          num: 0,
          extra: "",
        },
        {
          id: 4,
          title: "高效滤网",
          num: 0,
        },
      ],
      active: 1,
      resultData: [],
      listSum: 0,
      tableData: [],
      tabs: [
        {
          id: 1,
          title: "所有设备",
        },
        {
          id: 2,
          title: "已关机",
        },
        {
          id: 3,
          title: "已开机",
        },
        {
          id: 4,
          title: "待维护",
        },
        {
          id: 5,
          title: "需更换",
        },
      ],
      alarmList: [],
    };
  },
  methods: {
    changeTab(data) {
      if (data.id === 1) {
        this.tableData = [...this.resultData];
      } else if (data.id === 2) {
        this.tableData = this.resultData.filter((item) => {
          return !item.activeStatus;
        });
      } else if (data.id === 3) {
        this.tableData = this.resultData.filter((item) => {
          return item.activeStatus === 1;
        });
      } else if (data.id === 4) {
        this.tableData = this.resultData.filter((item) => {
          return item.isMaintaim;
        });
      } else {
        this.tableData = this.resultData.filter((item) => {
          return (
            item.highFilter / item.highFilterSum <= 0.05 ||
            item.mediumFilter / item.mediumFilterSum <= 0.05 ||
            item.primaryFilter / item.primaryFilterSum <= 0.05
          );
        });
      }
    },
    toDetail(title) {
      if (title === "新风系统") {
        this.$router.push({
          path: "detail/maintain",
        });
      } else {
        this.$router.push({
          path: "detail/filter",
          query: {
            title,
          },
        });
      }
    },
    //table设置完风机后刷新表格
    refreshTable(deviceId, data) {
      // console.log(deviceId, data);
      // console.log(this.resultData);
      this.resultData = this.resultData.map((item) => {
        if (item.deviceId === deviceId) {
          item.activeStatus = parseInt(data[0]);
          item.windOut = parseInt(data[1]);
        }
        return item;
      });
    },
    deviceList() {
      this.deviceTableLoading = true;
      this.$http
        .getDeviceList(window.localStorage.getItem("assetID"), 1, 10)
        .then((res) => {
          console.log(res), "resultData";
          this.listSum = res.data.listSum;
          this.resultData = res.data.page;
          this.tableData = [...this.resultData];
          this.deviceTableLoading = false;
        })
        .catch(() => {
          this.deviceTableLoading = false;
        });
    },
    //获取告警信息和滤网更换数量
    // getAlarmAndFilter() {
    //   this.$http
    //     .getFilterAndAlarmInfo("977054e0-f404-11eb-8077-cf8b89899ac4")
    //     .then((res) => {
    //       console.log(res.data.alarmInformation, "12");
    //       const alarmInformation = res?.data.alarmInformation || [];
    //       this.alarmList = alarmInformation.splice(0, 3);
    //       this.items[1].num = res?.data.primaryFilterNeedChangeNumber || 0;
    //       this.items[2].num = res?.data.mediumFilterNeedChangeNumber || 0;
    //       this.items[3].num = res?.data.highFilterNeedChangeNumber || 0;
    //     });
    // },
    //获取新风待维护数量
    // airWaitMaintain() {
    //   this.$http
    //     .getAirWaitMaintain("977054e0-f404-11eb-8077-cf8b89899ac4")
    //     .then((res) => {
    //       console.log(res);
    //       this.items[0].num = res?.data.waitMaintain || 0;
    //     });
    // },
  },
  created() {
    this.deviceList();
    this.$store.dispatch("getFilterState").then(() => {
      this.items[1].num = this.$store.state.filterState.primaryFilter;
      this.items[2].num = this.$store.state.filterState.mediumFilter;
      this.items[3].num = this.$store.state.filterState.highFilter;
    });
    this.$store.dispatch("getAirStateAction").then(() => {
      this.items[0].num = this.$store.state.airState;
    });

    // this.getAlarmAndFilter();
    // this.airWaitMaintain();
    // this.$http
    //   .getDeviceList("977054e0-f404-11eb-8077-cf8b89899ac4")
    //   .then((res) => {
    //     console.log(res);
    //   });
  },
  watch: {
    "$store.state.filterState"() {
      this.items[1].num = this.$store.state.filterState.primaryFilter;
      this.items[2].num = this.$store.state.filterState.mediumFilter;
      this.items[3].num = this.$store.state.filterState.highFilter;
    },
    "$store.state.airState"() {
      this.items[0].num = this.$store.state.airState;
    },
  },
};
</script>

<style scoped lang="scss">
.home-container {
  width: 100%;
  display: flex;
  .home-container-left {
    display: flex;
    flex-direction: column;
    width: 1280px;
    //width: 80%;
    .block-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .small-block {
        width: 20%;
        height: 100px;
        cursor: pointer;
      }
    }
    .tabs {
      width: 100%;
      height: 36px;
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > span {
        width: 100px;
        height: 100%;
        background-color: #e5e5e5;
        color: #333;
        cursor: pointer;
        margin-right: 5px;
        border-top-right-radius: 8px;
        line-height: 36px;
        text-align: center;
        &.is-active {
          background-color: #01cfa5;
          color: #fff;
        }
      }
    }
    .content {
      width: 100%;
      height: 654px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
      background-color: #fff;
    }
  }
  .home-container-right {
    margin-left: 30px;
    .state {
      width: 300px !important;
      height: 400px !important;
    }
    .online {
      margin-top: 20px;
      width: 300px !important;
      height: 220px !important;
    }
    .alarm1 {
      margin-top: 20px;
      width: 300px !important;
      height: 150px !important;
    }
  }
}
</style>
